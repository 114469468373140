import {
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  DEL_USER_FAIL,
  DEL_USER_REQ,
  DEL_USER_SUCC,
  GET_USERLIST_FAIL,
  GET_USERLIST_REQ,
  GET_USERLIST_SUCC,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  auth: false,
  token: "",
  userList: [],
  userAddress : null
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userAddress: payload,
        auth: true,
      };

    case ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case GET_USERLIST_REQ:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_USERLIST_SUCC:
      return {
        ...state,
        isLoading: false,
        userList: payload,
        isError: true,
      };

    case GET_USERLIST_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case DEL_USER_REQ:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case DEL_USER_SUCC:
      return {
        ...state,
        isLoading: false,

        isError: false,
      };

    case DEL_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
