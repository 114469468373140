/* eslint-disable no-undef */
import detectEthereumProvider from "@metamask/detect-provider";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import Adminlogin from "./Adminlogin";

const myContractABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_usdtAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_burnableAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_reciverAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "buyAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "referralReward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "timestamp",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "buyType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "referralAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
    ],
    name: "BuyTokenDetail",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "ETHORACLEADRESS",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ETHOracle",
    outputs: [
      {
        internalType: "contract OracleWrapper",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "USDTORACLEADRESS",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "USDTOracle",
    outputs: [
      {
        internalType: "contract OracleWrapper",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burnableAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burnablePercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_type",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "_referralAddress",
        type: "address",
      },
    ],
    name: "buyTokens",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_tokenPrice",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_type",
        type: "uint8",
      },
    ],
    name: "calculateTokens",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "endDate",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "firstPhaseSellToken",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "isInvested",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "randomString",
    outputs: [
      {
        internalType: "string",
        name: "referralCode",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "receiverAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "referralCodeMapping",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "referralPercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "startDate",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenInstance",
    outputs: [
      {
        internalType: "contract IERC20Metadata",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenPriceInPhaseOne",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenPriceInPhaseSecond",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenToBeSold",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalTokenSold",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_burnablePercentage",
        type: "uint256",
      },
    ],
    name: "updateBurnablePercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_receiverAddress",
        type: "address",
      },
    ],
    name: "updateReceiverAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_referralPercentage",
        type: "uint256",
      },
    ],
    name: "updateReferralPercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_userPercentage",
        type: "uint256",
      },
    ],
    name: "updateUserPercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "_vestingPeriod",
        type: "uint32",
      },
    ],
    name: "updatevestingTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "usdtInstance",
    outputs: [
      {
        internalType: "contract IERC20Metadata",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "userPercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "userVestingInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "claimedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "pendingAmount",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "buyTime",
        type: "uint32",
      },
      {
        internalType: "bool",
        name: "isClaimed",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingInstance",
    outputs: [
      {
        internalType: "contract IVesting",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingPeriod",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

const Settings = () => {
  const [myContract, setMyContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [owner, setCurrentOwner] = useState("");
  const [newOwnerAddress, setNewOwnerAddress] = useState("");
  const [currentReceiver, setCurrentReceiver] = useState("");
  const [newReceiverAddress, setNewReceiverAddress] = useState("");
  const [currentReferralPercentage , setCurrentReferralPercentage] = useState("")
  const [newReferralPercentage, setNewRefferalPercentage] = useState("");

  const myContractAddress = "0x72709AEAc74b8Ca7A1C4412Cf6E6a6d33469B893";

  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    const onConnect = async () => {
      try {
        // Detect and connect to the available wallet provider (MetaMask in this case)
        const provider = await detectEthereumProvider();
        if (!provider) {
          throw new Error(
            "No wallet provider detected. Please install Metamask."
          );
        }
        const web3Instance = new Web3(provider);

        setWeb3(web3Instance);

        // Request user permission to access accounts
        await web3Instance.eth.requestAccounts();

        const acc = await web3Instance.eth.getAccounts();

        // Instantiate contract instances
        const myContractInstance = new web3Instance.eth.Contract(
          myContractABI,
          myContractAddress
        );

        setMyContract(myContractInstance);

        // Fetch and set referral code if available

        setIsConnected(true);

        // Owner();
      } catch (error) {
        console.error("Connection error:", error);
        alert(error.message);
      }
    };

    onConnect();
  }, []);

  const Owner = async () => {
    try {
      const currentOwner = await myContract.methods.owner().call();
      console.log("Current owner:", currentOwner);
      setCurrentOwner(currentOwner);
    } catch (error) {
      console.error("Error fetching owner:", error);
    }
  };

  const debouncedOwner = debounce(Owner, 100);
  useEffect(() => {
    debouncedOwner();
  }, [myContract]);

  const getCurrentReceiver = async () => {
    try {
      const receiver = await myContract.methods.receiverAddress().call();
      console.log("Current Receiver :" ,  receiver)
      setCurrentReceiver(receiver);
    } catch (error) {
      console.error("Error fetching current receiver:", error);
      
    }
  };

  const debouncedReceiver = debounce(getCurrentReceiver, 100);

  useEffect(() => {
    debouncedReceiver();
  }, [myContract]);

  const transferOwnership = async () => {
    try {
      if (!newOwnerAddress) {
        alert("Please enter the new owner's address.");
        return;
      }

      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];

      // Call the transferOwnership function
      await myContract.methods.transferOwnership(newOwnerAddress).send({
        from: currentAccount,
      });

      alert("Ownership transferred successfully!");
    } catch (error) {
      console.error("Error transferring ownership:", error.message);
      alert(
        "Error transferring ownership. Please check the console for details."
      );
    }
  };

  const updateReceiver = async () => {
    try {
      if (!newReceiverAddress) {
        alert("Please enter the new receiver's address.");
        return;
      }

      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];

      // Call the updateReceiverAddress function
      await myContract.methods.updateReceiverAddress(newReceiverAddress).send({
        from: currentAccount,
      });

      alert("Receiver address updated successfully!");
    } catch (error) {
      console.error("Error updating receiver address:", error.message);
      alert("Error updating receiver address. Check the console for details.");
    }
  };

  const updateRefferal = async () => {
    try {
      if (!newReferralPercentage) {
        alert("Please enter the new referral percentage.");
        return;
      }

      // Multiply the entered value by 100
      const adjustedPercentage = parseFloat(newReferralPercentage) * 100;
      console.log("adjustedPercentage :",adjustedPercentage)

      if (adjustedPercentage < (0*100) || adjustedPercentage > (10*100)) {
        alert("Referral percentage must be between 0% and 10%.");
        return;
      }

      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];

      // Call the updateReferralPercentage function with the adjusted percentage
      await myContract.methods
        .updateReferralPercentage(adjustedPercentage)
        .send({
          from: currentAccount,
        });

      alert("Referral percentage updated successfully!");
    } catch (error) {
      console.error("Error updating referral percentage:", error.message);
      alert(
        "Error updating referral percentage. Check the console for details."
      );
    }
  };

  const getReferralPercentage = async () => {
    try {
      // Call the referralPercentage function from the smart contract
      const referralPercentage = await myContract.methods.referralPercentage().call();
      setCurrentReferralPercentage(referralPercentage.toString()/100)
      // Log or use the referralPercentage as needed
      console.log("Current Referral Percentage:", referralPercentage);
    } catch (error) {
      console.error("Error fetching referral percentage:", error.message);
      // Handle the error as needed
    }
  };
  
  const debounceReferralPercentage = debounce(getReferralPercentage , 100)


  // Call the function when the component mounts
  useEffect(() => {
    debounceReferralPercentage()
  }, [myContract]);

  return (
    <>
<Adminlogin/>
<div
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to bottom, #011B40, #DF5338)",
          filter: "blur(8px)", // Adjust the blur effect as needed
          zIndex: -1,
        }}
      ></div>

      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          marginTop: "16px",
          zIndex: 1,
        }}
      >
        <Paper elevation={3} style={{ padding: "16px" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Ownership Transfer
          </Typography>
          <TextField
            style={{ margin: "8px", width: "100%" }}
            variant="outlined"
            label="Current Owner"
            value={owner}
            InputProps={{
              readOnly: true,
            }}
          />
          {isConnected && (
            <div>
              <TextField
                style={{ margin: "8px", width: "100%" }}
                variant="outlined"
                label="New Owner's Address"
                placeholder="Enter new owner's address"
                value={newOwnerAddress}
                onChange={(e) => setNewOwnerAddress(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={transferOwnership}
              >
                Update Ownership
              </Button>
            </div>
          )}
        </Paper>
      </Container>

      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          marginTop: "16px",
          zIndex: 1,
        }}
      >
        <Paper elevation={3} style={{ padding: "16px" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Receiver Transfer
          </Typography>
          <TextField
            style={{ margin: "8px", width: "100%" }}
            variant="outlined"
            label="Current Receiver"
            value={currentReceiver}
            InputProps={{
              readOnly: true,
            }}
          />
          {isConnected && (
            <div>
              <TextField
                style={{ margin: "8px", width: "100%" }}
                variant="outlined"
                label="New Receiver's Address"
                placeholder="Enter new receiver's address"
                value={newReceiverAddress}
                onChange={(e) => setNewReceiverAddress(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={updateReceiver}
              >
                Update Receiver
              </Button>
            </div>
          )}
        </Paper>
      </Container>

      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          marginTop: "16px",
          zIndex: 1,
        }}
      >
        <Paper elevation={3} style={{ padding: "16px" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Referal Percentage
          </Typography>
          <TextField
            style={{ margin: "8px", width: "100%" , color:"black" }}
            variant="outlined"
            label="Current Referral Percentage"
            value={currentReferralPercentage}
            InputProps={{
              readOnly: true,
            }}
          />
          {isConnected && (
            <div>
              <TextField
                style={{ margin: "8px", width: "100%" }}
                variant="outlined"
                label="New Referal Percentage"
                placeholder="Enter new Referal percentage"
                value={newReferralPercentage}
                onChange={(e) => setNewRefferalPercentage(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={updateRefferal}
              >
                Update Referal percentage
              </Button>
            </div>
          )}
        </Paper>
      </Container>
    </div>
    </>
    
  );
};

export default Settings;
