export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST"
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS"
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE"


export const GET_USERLIST_REQ = "GET_USERLIST_REQ"
export const GET_USERLIST_SUCC = "GET_USERLIST_SUCC"
export const GET_USERLIST_FAIL = "GET_USERLIST_FAIL"

export const DEL_USER_REQ = "DEL_USER_REQ"
export const DEL_USER_SUCC = "DEL_USER_SUCC"
export const DEL_USER_FAIL = "DEL_USER_FAIL"