import axios from "axios";
import {
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  DEL_USER_FAIL,
  DEL_USER_REQ,
  DEL_USER_SUCC,
  GET_USERLIST_FAIL,
  GET_USERLIST_REQ,
  GET_USERLIST_SUCC,
} from "./actionTypes";
import Cookies from "js-cookie";

// export const login = (userData) => (dispatch) => {
//   dispatch({ type: ADMIN_LOGIN_REQUEST });
//   return axios
//     .post("http://localhost:4000/api/v1/user/adminLogin", userData)
//     .then((res) => {
//       dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: res.data.token });
//       Cookies.set("adminuserauth", res.data.token, { expires: 1 });
//       console.log(res.data);
//     })
//     .catch((err) => {
//       dispatch({ type: ADMIN_LOGIN_FAILURE, payload: err.response.data.msg });
//       console.log(err.response.data.msg);
//       throw err;
//     });
// };

export const login = (userAddress) =>({
  type : ADMIN_LOGIN_SUCCESS,
  payload : userAddress

})

export const getUserList = (queryParams) => (dispatch) => {
  
  dispatch({ type: GET_USERLIST_REQ });
  return axios
    .get("https://api.oneforallcoin.com/api/v1/user/user-list", {
      params: queryParams,
    })
    .then((res) => {
      dispatch({ type: GET_USERLIST_SUCC, payload: res.data });
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_USERLIST_FAIL,
      });
      throw error;
    });
};

export const deleteUser = (userID) => (dispatch) => {
    console.log('Deleting user with ID:', userID)
  dispatch({ type: DEL_USER_REQ });
  return axios
    .delete(`https://api.oneforallcoin.com/api/v1/user/${userID}`)
    .then(() => {
      dispatch({ type: DEL_USER_SUCC });
    })
    .catch((err) => {
      dispatch({
        type: DEL_USER_FAIL,
      });
      throw err
    });
};
