import logo from "./logo.svg";
import "./App.css";
import Adminlogin from "./Pages/Adminlogin";
import MainRoutes from "./Components/MainRoutes";
import Settings from "./Pages/Settings";


function App() {
  return (
    <div className="App">
      <MainRoutes />
    {/* <Settings/> */}
    {/* <Adminlogin/> */}
    </div>
  );
}

export default App;
