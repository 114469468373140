import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUserList } from "../redux/adminAuth/action";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Slide,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Adminlogin from "./Adminlogin";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserList = () => {
  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 600,
    margin: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  };

  const avatarStyle = {
    width: "80px",
    height: "80px",
    margin: "0 auto",
  };
  const dispatch = useDispatch();
  const userList = useSelector((store) => store.adminauthReducer.userList);
  console.log(userList);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const queryParams = {
      // Define your query parameters here, e.g., page, limit, search
      page: 1,
      limit: 10,
    };

    dispatch(getUserList(queryParams));
  }, [dispatch]);

  const handleDeleteUser = (userID) => {
    dispatch(deleteUser(userID)).then(() => {
      // After successful deletion, fetch the updated user list
      setOpen(false)
      dispatch(getUserList({ page: 1, limit: 10 }));
    });
  };

  return (
   <>
   <Adminlogin/>
   <div style={{ display: "flex", width: "90%", margin: "auto" }}>
      {userList?.data?.docs.map((user) => (
        <Card style={cardStyle}>
          <CardContent>
            <Avatar style={avatarStyle} src={user.avatarUrl} alt={user.name} />
            <Typography
              variant="h5"
              align="center"
              style={{ marginTop: "0.5rem" }}
            >
              {user.name}
            </Typography>
            <Typography color="textSecondary" align="center">
              {user.email}
            </Typography>
            {/* Add other user details as needed */}
            <Fab
              size="small"
              color="secondary"
              aria-label="add"
              onClick={handleClickOpen}
            >
              <Delete />
            </Fab>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Delete"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are You sure you want to delete the user details. The changes
                  cannot be reverted back.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={() => handleDeleteUser(user._id)}>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      ))}
    </div>
   </>
  );
};

export default UserList;
