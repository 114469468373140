import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login } from "../redux/adminAuth/action";
import { Alert, AlertTitle, AppBar, Toolbar } from "@mui/material";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://Oneforallcoin.com">
        Oneforallcoin.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const myContractABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_usdtAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_burnableAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_reciverAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "buyAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "referralReward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "timestamp",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "buyType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "referralAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
    ],
    name: "BuyTokenDetail",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "ETHORACLEADRESS",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ETHOracle",
    outputs: [
      {
        internalType: "contract OracleWrapper",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "USDTORACLEADRESS",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "USDTOracle",
    outputs: [
      {
        internalType: "contract OracleWrapper",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burnableAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burnablePercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_type",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "_referralAddress",
        type: "address",
      },
    ],
    name: "buyTokens",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_tokenPrice",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_type",
        type: "uint8",
      },
    ],
    name: "calculateTokens",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "endDate",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "firstPhaseSellToken",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "isInvested",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "randomString",
    outputs: [
      {
        internalType: "string",
        name: "referralCode",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "receiverAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "referralCodeMapping",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "referralPercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "startDate",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenInstance",
    outputs: [
      {
        internalType: "contract IERC20Metadata",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenPriceInPhaseOne",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenPriceInPhaseSecond",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenToBeSold",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalTokenSold",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_burnablePercentage",
        type: "uint256",
      },
    ],
    name: "updateBurnablePercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_receiverAddress",
        type: "address",
      },
    ],
    name: "updateReceiverAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_referralPercentage",
        type: "uint256",
      },
    ],
    name: "updateReferralPercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_userPercentage",
        type: "uint256",
      },
    ],
    name: "updateUserPercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "_vestingPeriod",
        type: "uint32",
      },
    ],
    name: "updatevestingTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "usdtInstance",
    outputs: [
      {
        internalType: "contract IERC20Metadata",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "userPercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "userVestingInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "claimedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "pendingAmount",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "buyTime",
        type: "uint32",
      },
      {
        internalType: "bool",
        name: "isClaimed",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingInstance",
    outputs: [
      {
        internalType: "contract IVesting",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingPeriod",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

export default function Adminlogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertComponent, setAlertComponent] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [myContract, setMyContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [loginSuccessful, setLoginSuccessful] = useState(false);

  const dispatch = useDispatch();

  const myContractAddress = "0x72709AEAc74b8Ca7A1C4412Cf6E6a6d33469B893";

  useEffect(() => {
    const onConnect = async () => {
      try {
        // Detect and connect to the available wallet provider (MetaMask in this case)
        const provider = await detectEthereumProvider();
        if (!provider) {
          throw new Error(
            "No wallet provider detected. Please install Metamask."
          );
        }
        const web3Instance = new Web3(provider);

        setWeb3(web3Instance);

        // Request user permission to access accounts
        await web3Instance.eth.requestAccounts();

        const accounts = await web3Instance.eth.getAccounts();
        const userAddress = accounts[0]; // Assuming the first account is the user's address

        // setUserAddress(userAddress);

        // Instantiate contract instances
        const myContractInstance = new web3Instance.eth.Contract(
          myContractABI,
          myContractAddress
        );

        setMyContract(myContractInstance);

        setIsConnected(true);

        // Check if the user address is equal to the owner address
        const ownerAddress = await myContractInstance.methods.owner().call();

        if (userAddress === ownerAddress) {
          console.log("Login successful");
          // Dispatch your login action or set login state as needed
          dispatch(login(userAddress));
          // console.log(login(userAddress))
          // alert("Login Successfull")
          setLoginSuccessful(true)
        } else {
          console.log("Login failed: User address is not the owner address");
          // Handle failed login, e.g., display an error message
        }
      } catch (error) {
        console.error("Connection error:", error);
        alert(error.message);
      }
    };

    onConnect();
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const userData = {
  //     email,
  //     password,
  //   };

  //   dispatch(login(userData))
  //     .then(() => {
  //       const alertComponent = (
  //         <Alert
  //           variant="filled"
  //           severity="success"
  //           onClose={() => setAlertComponent(null)}
  //         >
  //           <AlertTitle>Login Successfull!!</AlertTitle>
  //           <strong>Redirecting to you on homepage.</strong>
  //         </Alert>
  //       );
  //       setAlertComponent(alertComponent);

  //       // Navigate after a delay, or when the user closes the alert
  //       setTimeout(() => {
  //         navigate(location.state, { replace: true });
  //         navigate("/user-details");
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       const alertComponent = (
  //         <Alert
  //           variant="filled"
  //           severity="error"
  //           onClose={() => setAlertComponent(null)}
  //         >
  //           <AlertTitle>Error</AlertTitle>
  //           {err.response.data.msg} <strong>check it out once!</strong>
  //         </Alert>
  //       );
  //       setAlertComponent(alertComponent);

  //       setTimeout(() => {
  //         setAlertComponent(null);
  //       }, 3000);
  //     })
  //     .finally(() => {
  //       setEmail("");
  //       setPassword("");
  //     });
  // };


  const userClick = () => {
    navigate("/user-details")
  }

  const settingsClick = () => {
    navigate("/settings")
  }

  return (
    // <ThemeProvider theme={defaultTheme}>
    //   <Container component="main" maxWidth="xs">
    //     <CssBaseline />
    //     <Box
    //       sx={{
    //         marginTop: 8,
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
    //         <LockOpenIcon />
    //       </Avatar>
    //       <Typography component="h1" variant="h5">
    //         Ofa Admin Sign in
    //       </Typography>
    //       <Box
    //         component="form"
    //         onSubmit={handleSubmit}
    //         noValidate
    //         sx={{ mt: 1 }}
    //       >
    //         <TextField
    //           margin="normal"
    //           required
    //           fullWidth
    //           id="email"
    //           label="Email Address"
    //           name="email"
    //           autoComplete="email"
    //           autoFocus
    //           value={email}
    //           onChange={(e) => {
    //             setEmail(e.target.value);
    //           }}
    //         />
    //         <TextField
    //           margin="normal"
    //           required
    //           fullWidth
    //           name="password"
    //           label="Password"
    //           type="password"
    //           id="password"
    //           autoComplete="current-password"
    //           value={password}
    //           onChange={(e) => {
    //             setPassword(e.target.value);
    //           }}
    //         />

    //         <Button
    //           type="submit"
    //           fullWidth
    //           variant="contained"
    //           sx={{ mt: 3, mb: 2 }}
    //         >
    //           Sign In
    //         </Button>
    //       </Box>
    //     </Box>
    //     <Copyright sx={{ mt: 8, mb: 4 }} />

    //     {alertComponent && (
    //       <div className="alert-container">{alertComponent}</div>
    //     )}
    //   </Container>
    // </ThemeProvider>
    <>
  <div style={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            OFA Admin
          </Typography>
          
          <Button
            color="inherit"
            disabled={!isConnected || !loginSuccessful}
            style={{ marginLeft: '10px' }}
            onClick={userClick}
          >
           UserList
          </Button>
        
          
          <Button
            color="inherit"
            disabled={!isConnected || !loginSuccessful}
            style={{ marginLeft: '10px' }}
            onClick={settingsClick}
          >
           Settings
          </Button>
         
        </Toolbar>
      </AppBar>
    </div>
    </>
  );
}
