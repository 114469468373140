import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Adminlogin from '../Pages/Adminlogin'
import UserList from '../Pages/UserList'
import Settings from '../Pages/Settings'

const MainRoutes = () => {
  return (
   <Routes>
    <Route path='/' element={<Adminlogin/>} />
    <Route path='/user-details' element={<UserList/>} />
    <Route path='/settings' element={<Settings/>}/>
   </Routes>
  )
}

export default MainRoutes